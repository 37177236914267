// import { v4 as uuidv4 } from 'uuid';

// export const CountryCodes = [
//   { id: uuidv4(), country: "Ivory Coast", code: "225", iso: "CI" },
//   { id: uuidv4(), country: "Jamaica", code: "1-876", iso: "JM" },
//   { id: uuidv4(), country: "Japan", code: "81", iso: "JP" },
//   { id: uuidv4(), country: "Jersey", code: "44-1534", iso: "JE" },
//   { id: uuidv4(), country: "Jordan", code: "962", iso: "JO" },
//   { id: uuidv4(), country: "Kazakhstan", code: "7", iso: "KZ" },
//   { id: uuidv4(), country: "Kenya", code: "254", iso: "KE" },
//   { id: uuidv4(), country: "Kiribati", code: "686", iso: "KI" },
//   { id: uuidv4(), country: "Kosovo", code: "383", iso: "XK" },
//   { id: uuidv4(), country: "Kuwait", code: "965", iso: "KW" },
//   { id: uuidv4(), country: "Kyrgyzstan", code: "996", iso: "KG" },
//   { id: uuidv4(), country: "Laos", code: "856", iso: "LA" },
//   { id: uuidv4(), country: "Latvia", code: "371", iso: "LV" },
//   { id: uuidv4(), country: "Lebanon", code: "961", iso: "LB" },
//   { id: uuidv4(), country: "Lesotho", code: "266", iso: "LS" },
//   { id: uuidv4(), country: "Liberia", code: "231", iso: "LR" },
//   { id: uuidv4(), country: "Libya", code: "218", iso: "LY" },
//   { id: uuidv4(), country: "Liechtenstein", code: "423", iso: "LI" },
//   { id: uuidv4(), country: "Lithuania", code: "370", iso: "LT" },
//   { id: uuidv4(), country: "Luxembourg", code: "352", iso: "LU" },
//   { id: uuidv4(), country: "Macao", code: "853", iso: "MO" },
//   { id: uuidv4(), country: "Macedonia", code: "389", iso: "MK" },
//   { id: uuidv4(), country: "Madagascar", code: "261", iso: "MG" },
//   { id: uuidv4(), country: "Malawi", code: "265", iso: "MW" },
//   { id: uuidv4(), country: "Malaysia", code: "60", iso: "MY" },
//   { id: uuidv4(), country: "Maldives", code: "960", iso: "MV" },
//   { id: uuidv4(), country: "Mali", code: "223", iso: "ML" },
//   { id: uuidv4(), country: "Malta", code: "356", iso: "MT" },
//   { id: uuidv4(), country: "Marshall Islands", code: "692", iso: "MH" },
//   { id: uuidv4(), country: "Mauritania", code: "222", iso: "MR" },
//   { id: uuidv4(), country: "Mauritius", code: "230", iso: "MU" },
//   //   { id:uuidv4(),country: "Mayotte", code: "262", iso: "YT" },
//   { id: uuidv4(), country: "Mexico", code: "52", iso: "MX" },
//   { id: uuidv4(), country: "Micronesia", code: "691", iso: "FM" },
//   { id: uuidv4(), country: "Moldova", code: "373", iso: "MD" },
//   { id: uuidv4(), country: "Monaco", code: "377", iso: "MC" },
//   { id: uuidv4(), country: "Mongolia", code: "976", iso: "MN" },
//   { id: uuidv4(), country: "Montenegro", code: "382", iso: "ME" },
//   { id: uuidv4(), country: "Montserrat", code: "1-664", iso: "MS" },
//   { id: uuidv4(), country: "Morocco", code: "212", iso: "MA" },
//   { id: uuidv4(), country: "Mozambique", code: "258", iso: "MZ" },
//   { id: uuidv4(), country: "Myanmar", code: "95", iso: "MM" },
//   { id: uuidv4(), country: "Namibia", code: "264", iso: "NA" },
//   { id: uuidv4(), country: "Nauru", code: "674", iso: "NR" },
//   { id: uuidv4(), country: "Nepal", code: "977", iso: "NP" },
//   { id: uuidv4(), country: "Netherlands", code: "31", iso: "NL" },
//   { id: uuidv4(), country: "Netherlands Antilles", code: "599", iso: "AN" },
//   { id: uuidv4(), country: "New Caledonia", code: "687", iso: "NC" },
//   { id: uuidv4(), country: "New Zealand", code: "64", iso: "NZ" },
//   { id: uuidv4(), country: "Nicaragua", code: "505", iso: "NI" },
//   { id: uuidv4(), country: "Niger", code: "227", iso: "NE" },
//   { id: uuidv4(), country: "Nigeria", code: "234", iso: "NG" },
//   { id: uuidv4(), country: "Niue", code: "683", iso: "NU" },
//   { id: uuidv4(), country: "North Korea", code: "850", iso: "KP" },
//   {
//     id: uuidv4(),
//     country: "Northern Mariana Islands",
//     code: "1-670",
//     iso: "MP",
//   },
//   { id: uuidv4(), country: "Norway", code: "47", iso: "NO" },
//   { id: uuidv4(), country: "Oman", code: "968", iso: "OM" },
//   { id: uuidv4(), country: "Pakistan", code: "92", iso: "PK" },
//   { id: uuidv4(), country: "Palau", code: "680", iso: "PW" },
//   { id: uuidv4(), country: "Palestine", code: "970", iso: "PS" },
//   { id: uuidv4(), country: "Panama", code: "507", iso: "PA" },
//   { id: uuidv4(), country: "Papua New Guinea", code: "675", iso: "PG" },
//   { id: uuidv4(), country: "Paraguay", code: "595", iso: "PY" },
//   { id: uuidv4(), country: "Peru", code: "51", iso: "PE" },
//   { id: uuidv4(), country: "Philippines", code: "63", iso: "PH" },
//   //   { id:uuidv4(),country: "Pitcairn", code: "64", iso: "PN" },
//   { id: uuidv4(), country: "Poland", code: "48", iso: "PL" },
//   { id: uuidv4(), country: "Portugal", code: "351", iso: "PT" },
//   { id: uuidv4(), country: "Puerto Rico", code: "1-787, 1-939", iso: "PR" },
//   { id: uuidv4(), country: "Qatar", code: "974", iso: "QA" },
//   { id: uuidv4(), country: "Republic of the Congo", code: "242", iso: "CG" },
//   { id: uuidv4(), country: "Reunion", code: "262", iso: "RE" },
//   { id: uuidv4(), country: "Romania", code: "40", iso: "RO" },
//   { id: uuidv4(), country: "Russia", code: "7", iso: "RU" },
//   { id: uuidv4(), country: "Rwanda", code: "250", iso: "RW" },
//   { id: uuidv4(), country: "Saint Barthelemy", code: "590", iso: "BL" },
//   { id: uuidv4(), country: "Saint Helena", code: "290", iso: "SH" },
//   { id: uuidv4(), country: "Saint Kitts and Nevis", code: "1-869", iso: "KN" },
//   { id: uuidv4(), country: "Saint Lucia", code: "1-758", iso: "LC" },
//   { id: uuidv4(), country: "Saint Martin", code: "590", iso: "MF" },
//   {
//     id: uuidv4(),
//     country: "Saint Pierre and Miquelon",
//     code: "508",
//     iso: "PM",
//   },
//   {
//     id: uuidv4(),
//     country: "Saint Vincent and the Grenadines",
//     code: "1-784",
//     iso: "VC",
//   },
//   { id: uuidv4(), country: "Samoa", code: "685", iso: "WS" },
//   { id: uuidv4(), country: "San Marino", code: "378", iso: "SM" },
//   { id: uuidv4(), country: "Sao Tome and Principe", code: "239", iso: "ST" },
//   { id: uuidv4(), country: "Saudi Arabia", code: "966", iso: "SA" },
//   { id: uuidv4(), country: "Senegal", code: "221", iso: "SN" },
//   { id: uuidv4(), country: "Serbia", code: "381", iso: "RS" },
//   { id: uuidv4(), country: "Seychelles", code: "248", iso: "SC" },
//   { id: uuidv4(), country: "Sierra Leone", code: "232", iso: "SL" },
//   { id: uuidv4(), country: "Singapore", code: "65", iso: "SG" },
//   { id: uuidv4(), country: "Sint Maarten", code: "1-721", iso: "SX" },
//   { id: uuidv4(), country: "Slovakia", code: "421", iso: "SK" },
//   { id: uuidv4(), country: "Slovenia", code: "386", iso: "SI" },
//   { id: uuidv4(), country: "Solomon Islands", code: "677", iso: "SB" },
//   { id: uuidv4(), country: "Somalia", code: "252", iso: "SO" },
//   { id: uuidv4(), country: "South Africa", code: "27", iso: "ZA" },
//   { id: uuidv4(), country: "South Korea", code: "82", iso: "KR" },
//   { id: uuidv4(), country: "South Sudan", code: "211", iso: "SS" },
//   { id: uuidv4(), country: "Spain", code: "34", iso: "ES" },
//   { id: uuidv4(), country: "Sri Lanka", code: "94", iso: "LK" },
//   { id: uuidv4(), country: "Sudan", code: "249", iso: "SD" },
//   { id: uuidv4(), country: "Suriname", code: "597", iso: "SR" },
//   { id: uuidv4(), country: "Svalbard and Jan Mayen", code: "47", iso: "SJ" },
//   { id: uuidv4(), country: "Swaziland", code: "268", iso: "SZ" },
//   { id: uuidv4(), country: "Sweden", code: "46", iso: "SE" },
//   { id: uuidv4(), country: "Switzerland", code: "41", iso: "CH" },
//   { id: uuidv4(), country: "Syria", code: "963", iso: "SY" },
//   { id: uuidv4(), country: "Taiwan", code: "886", iso: "TW" },
//   { id: uuidv4(), country: "Tajikistan", code: "992", iso: "TJ" },
//   { id: uuidv4(), country: "Tanzania", code: "255", iso: "TZ" },
//   { id: uuidv4(), country: "Thailand", code: "66", iso: "TH" },
//   { id: uuidv4(), country: "Togo", code: "228", iso: "TG" },
//   { id: uuidv4(), country: "Tokelau", code: "690", iso: "TK" },
//   { id: uuidv4(), country: "Tonga", code: "676", iso: "TO" },
//   { id: uuidv4(), country: "Trinidad and Tobago", code: "1-868", iso: "TT" },
//   { id: uuidv4(), country: "Tunisia", code: "216", iso: "TN" },
//   { id: uuidv4(), country: "Turkey", code: "90", iso: "TR" },
//   { id: uuidv4(), country: "Turkmenistan", code: "993", iso: "TM" },
//   {
//     id: uuidv4(),
//     country: "Turks and Caicos Islands",
//     code: "1-649",
//     iso: "TC",
//   },
//   { id: uuidv4(), country: "Tuvalu", code: "688", iso: "TV" },
//   { id: uuidv4(), country: "U.S. Virgin Islands", code: "1-340", iso: "VI" },
//   { id: uuidv4(), country: "Uganda", code: "256", iso: "UG" },
//   { id: uuidv4(), country: "Ukraine", code: "380", iso: "UA" },
//   { id: uuidv4(), country: "United Arab Emirates", code: "971", iso: "AE" },
//   { id: uuidv4(), country: "United Kingdom", code: "44", iso: "GB" },
//   { id: uuidv4(), country: "United States", code: "1", iso: "US" },
//   { id: uuidv4(), country: "Uruguay", code: "598", iso: "UY" },
//   { id: uuidv4(), country: "Uzbekistan", code: "998", iso: "UZ" },
//   { id: uuidv4(), country: "Vanuatu", code: "678", iso: "VU" },
//   { id: uuidv4(), country: "Vatican", code: "379", iso: "VA" },
//   { id: uuidv4(), country: "Venezuela", code: "58", iso: "VE" },
//   { id: uuidv4(), country: "Vietnam", code: "84", iso: "VN" },
//   { id: uuidv4(), country: "Wallis and Futuna", code: "681", iso: "WF" },
//   { id: uuidv4(), country: "Western Sahara", code: "212", iso: "EH" },
//   { id: uuidv4(), country: "Yemen", code: "967", iso: "YE" },
//   { id: uuidv4(), country: "Zambia", code: "260", iso: "ZM" },
//   { id: uuidv4(), country: "Zimbabwe", code: "263", iso: "ZW" },
// ];
// export const CountryCodes = [
//   {
//     name: "Afghanistan",
//     dial_code: "+93",
//     code: "AF",
//   },
//   {
//     name: "Aland Islands",
//     dial_code: "+358",
//     code: "AX",
//   },
//   {
//     name: "Albania",
//     dial_code: "+355",
//     code: "AL",
//   },
//   {
//     name: "Algeria",
//     dial_code: "+213",
//     code: "DZ",
//   },
//   {
//     name: "AmericanSamoa",
//     dial_code: "+1684",
//     code: "AS",
//   },
//   {
//     name: "Andorra",
//     dial_code: "+376",
//     code: "AD",
//   },
//   {
//     name: "Angola",
//     dial_code: "+244",
//     code: "AO",
//   },
//   {
//     name: "Anguilla",
//     dial_code: "+1264",
//     code: "AI",
//   },
//   {
//     name: "Antarctica",
//     dial_code: "+672",
//     code: "AQ",
//   },
//   {
//     name: "Antigua and Barbuda",
//     dial_code: "+1268",
//     code: "AG",
//   },
//   {
//     name: "Argentina",
//     dial_code: "+54",
//     code: "AR",
//   },
//   {
//     name: "Armenia",
//     dial_code: "+374",
//     code: "AM",
//   },
//   {
//     name: "Aruba",
//     dial_code: "+297",
//     code: "AW",
//   },
//   {
//     name: "Australia",
//     dial_code: "+61",
//     code: "AU",
//   },
//   {
//     name: "Austria",
//     dial_code: "+43",
//     code: "AT",
//   },
//   {
//     name: "Azerbaijan",
//     dial_code: "+994",
//     code: "AZ",
//   },
//   {
//     name: "Bahamas",
//     dial_code: "+1242",
//     code: "BS",
//   },
//   {
//     name: "Bahrain",
//     dial_code: "+973",
//     code: "BH",
//   },
//   {
//     name: "Bangladesh",
//     dial_code: "+880",
//     code: "BD",
//   },
//   {
//     name: "Barbados",
//     dial_code: "+1246",
//     code: "BB",
//   },
//   {
//     name: "Belarus",
//     dial_code: "+375",
//     code: "BY",
//   },
//   {
//     name: "Belgium",
//     dial_code: "+32",
//     code: "BE",
//   },
//   {
//     name: "Belize",
//     dial_code: "+501",
//     code: "BZ",
//   },
//   {
//     name: "Benin",
//     dial_code: "+229",
//     code: "BJ",
//   },
//   {
//     name: "Bermuda",
//     dial_code: "+1441",
//     code: "BM",
//   },
//   {
//     name: "Bhutan",
//     dial_code: "+975",
//     code: "BT",
//   },
//   {
//     name: "Bolivia, Plurinational State of",
//     dial_code: "+591",
//     code: "BO",
//   },
//   {
//     name: "Bosnia and Herzegovina",
//     dial_code: "+387",
//     code: "BA",
//   },
//   {
//     name: "Botswana",
//     dial_code: "+267",
//     code: "BW",
//   },
//   {
//     name: "Brazil",
//     dial_code: "+55",
//     code: "BR",
//   },
//   {
//     name: "British Indian Ocean Territory",
//     dial_code: "+246",
//     code: "IO",
//   },
//   {
//     name: "Brunei Darussalam",
//     dial_code: "+673",
//     code: "BN",
//   },
//   {
//     name: "Bulgaria",
//     dial_code: "+359",
//     code: "BG",
//   },
//   {
//     name: "Burkina Faso",
//     dial_code: "+226",
//     code: "BF",
//   },
//   {
//     name: "Burundi",
//     dial_code: "+257",
//     code: "BI",
//   },
//   {
//     name: "Cambodia",
//     dial_code: "+855",
//     code: "KH",
//   },
//   {
//     name: "Cameroon",
//     dial_code: "+237",
//     code: "CM",
//   },
//   {
//     name: "Canada",
//     dial_code: "+1",
//     code: "CA",
//   },
//   {
//     name: "Cape Verde",
//     dial_code: "+238",
//     code: "CV",
//   },
//   {
//     name: "Cayman Islands",
//     dial_code: "+ 345",
//     code: "KY",
//   },
//   {
//     name: "Central African Republic",
//     dial_code: "+236",
//     code: "CF",
//   },
//   {
//     name: "Chad",
//     dial_code: "+235",
//     code: "TD",
//   },
//   {
//     name: "Chile",
//     dial_code: "+56",
//     code: "CL",
//   },
//   {
//     name: "China",
//     dial_code: "+86",
//     code: "CN",
//   },
//   {
//     name: "Christmas Island",
//     dial_code: "+61",
//     code: "CX",
//   },
//   {
//     name: "Cocos (Keeling) Islands",
//     dial_code: "+61",
//     code: "CC",
//   },
//   {
//     name: "Colombia",
//     dial_code: "+57",
//     code: "CO",
//   },
//   {
//     name: "Comoros",
//     dial_code: "+269",
//     code: "KM",
//   },
//   {
//     name: "Congo",
//     dial_code: "+242",
//     code: "CG",
//   },
//   {
//     name: "Congo, The Democratic Republic of the Congo",
//     dial_code: "+243",
//     code: "CD",
//   },
//   {
//     name: "Cook Islands",
//     dial_code: "+682",
//     code: "CK",
//   },
//   {
//     name: "Costa Rica",
//     dial_code: "+506",
//     code: "CR",
//   },
//   {
//     name: "Cote d'Ivoire",
//     dial_code: "+225",
//     code: "CI",
//   },
//   {
//     name: "Croatia",
//     dial_code: "+385",
//     code: "HR",
//   },
//   {
//     name: "Cuba",
//     dial_code: "+53",
//     code: "CU",
//   },
//   {
//     name: "Cyprus",
//     dial_code: "+357",
//     code: "CY",
//   },
//   {
//     name: "Czech Republic",
//     dial_code: "+420",
//     code: "CZ",
//   },
//   {
//     name: "Denmark",
//     dial_code: "+45",
//     code: "DK",
//   },
//   {
//     name: "Djibouti",
//     dial_code: "+253",
//     code: "DJ",
//   },
//   {
//     name: "Dominica",
//     dial_code: "+1767",
//     code: "DM",
//   },
//   {
//     name: "Dominican Republic",
//     dial_code: "+1849",
//     code: "DO",
//   },
//   {
//     name: "Ecuador",
//     dial_code: "+593",
//     code: "EC",
//   },
//   {
//     name: "Egypt",
//     dial_code: "+20",
//     code: "EG",
//   },
//   {
//     name: "El Salvador",
//     dial_code: "+503",
//     code: "SV",
//   },
//   {
//     name: "Equatorial Guinea",
//     dial_code: "+240",
//     code: "GQ",
//   },
//   {
//     name: "Eritrea",
//     dial_code: "+291",
//     code: "ER",
//   },
//   {
//     name: "Estonia",
//     dial_code: "+372",
//     code: "EE",
//   },
//   {
//     name: "Ethiopia",
//     dial_code: "+251",
//     code: "ET",
//   },
//   {
//     name: "Falkland Islands (Malvinas)",
//     dial_code: "+500",
//     code: "FK",
//   },
//   {
//     name: "Faroe Islands",
//     dial_code: "+298",
//     code: "FO",
//   },
//   {
//     name: "Fiji",
//     dial_code: "+679",
//     code: "FJ",
//   },
//   {
//     name: "Finland",
//     dial_code: "+358",
//     code: "FI",
//   },
//   {
//     name: "France",
//     dial_code: "+33",
//     code: "FR",
//   },
//   {
//     name: "French Guiana",
//     dial_code: "+594",
//     code: "GF",
//   },
//   {
//     name: "French Polynesia",
//     dial_code: "+689",
//     code: "PF",
//   },
//   {
//     name: "Gabon",
//     dial_code: "+241",
//     code: "GA",
//   },
//   {
//     name: "Gambia",
//     dial_code: "+220",
//     code: "GM",
//   },
//   {
//     name: "Georgia",
//     dial_code: "+995",
//     code: "GE",
//   },
//   {
//     name: "Germany",
//     dial_code: "+49",
//     code: "DE",
//   },
//   {
//     name: "Ghana",
//     dial_code: "+233",
//     code: "GH",
//   },
//   {
//     name: "Gibraltar",
//     dial_code: "+350",
//     code: "GI",
//   },
//   {
//     name: "Greece",
//     dial_code: "+30",
//     code: "GR",
//   },
//   {
//     name: "Greenland",
//     dial_code: "+299",
//     code: "GL",
//   },
//   {
//     name: "Grenada",
//     dial_code: "+1473",
//     code: "GD",
//   },
//   {
//     name: "Guadeloupe",
//     dial_code: "+590",
//     code: "GP",
//   },
//   {
//     name: "Guam",
//     dial_code: "+1671",
//     code: "GU",
//   },
//   {
//     name: "Guatemala",
//     dial_code: "+502",
//     code: "GT",
//   },
//   {
//     name: "Guernsey",
//     dial_code: "+44",
//     code: "GG",
//   },
//   {
//     name: "Guinea",
//     dial_code: "+224",
//     code: "GN",
//   },
//   {
//     name: "Guinea-Bissau",
//     dial_code: "+245",
//     code: "GW",
//   },
//   {
//     name: "Guyana",
//     dial_code: "+595",
//     code: "GY",
//   },
//   {
//     name: "Haiti",
//     dial_code: "+509",
//     code: "HT",
//   },
//   {
//     name: "Holy See (Vatican City State)",
//     dial_code: "+379",
//     code: "VA",
//   },
//   {
//     name: "Honduras",
//     dial_code: "+504",
//     code: "HN",
//   },
//   {
//     name: "Hong Kong",
//     dial_code: "+852",
//     code: "HK",
//   },
//   {
//     name: "Hungary",
//     dial_code: "+36",
//     code: "HU",
//   },
//   {
//     name: "Iceland",
//     dial_code: "+354",
//     code: "IS",
//   },
//   {
//     name: "India",
//     dial_code: "+91",
//     code: "IN",
//   },
//   {
//     name: "Indonesia",
//     dial_code: "+62",
//     code: "ID",
//   },
//   {
//     name: "Iran, Islamic Republic of Persian Gulf",
//     dial_code: "+98",
//     code: "IR",
//   },
//   {
//     name: "Iraq",
//     dial_code: "+964",
//     code: "IQ",
//   },
//   {
//     name: "Ireland",
//     dial_code: "+353",
//     code: "IE",
//   },
//   {
//     name: "Isle of Man",
//     dial_code: "+44",
//     code: "IM",
//   },
//   {
//     name: "Israel",
//     dial_code: "+972",
//     code: "IL",
//   },
//   {
//     name: "Italy",
//     dial_code: "+39",
//     code: "IT",
//   },
//   {
//     name: "Jamaica",
//     dial_code: "+1876",
//     code: "JM",
//   },
//   {
//     name: "Japan",
//     dial_code: "+81",
//     code: "JP",
//   },
//   {
//     name: "Jersey",
//     dial_code: "+44",
//     code: "JE",
//   },
//   {
//     name: "Jordan",
//     dial_code: "+962",
//     code: "JO",
//   },
//   {
//     name: "Kazakhstan",
//     dial_code: "+77",
//     code: "KZ",
//   },
//   {
//     name: "Kenya",
//     dial_code: "+254",
//     code: "KE",
//   },
//   {
//     name: "Kiribati",
//     dial_code: "+686",
//     code: "KI",
//   },
//   {
//     name: "Korea, Democratic People's Republic of Korea",
//     dial_code: "+850",
//     code: "KP",
//   },
//   {
//     name: "Korea, Republic of South Korea",
//     dial_code: "+82",
//     code: "KR",
//   },
//   {
//     name: "Kuwait",
//     dial_code: "+965",
//     code: "KW",
//   },
//   {
//     name: "Kyrgyzstan",
//     dial_code: "+996",
//     code: "KG",
//   },
//   {
//     name: "Laos",
//     dial_code: "+856",
//     code: "LA",
//   },
//   {
//     name: "Latvia",
//     dial_code: "+371",
//     code: "LV",
//   },
//   {
//     name: "Lebanon",
//     dial_code: "+961",
//     code: "LB",
//   },
//   {
//     name: "Lesotho",
//     dial_code: "+266",
//     code: "LS",
//   },
//   {
//     name: "Liberia",
//     dial_code: "+231",
//     code: "LR",
//   },
//   {
//     name: "Libyan Arab Jamahiriya",
//     dial_code: "+218",
//     code: "LY",
//   },
//   {
//     name: "Liechtenstein",
//     dial_code: "+423",
//     code: "LI",
//   },
//   {
//     name: "Lithuania",
//     dial_code: "+370",
//     code: "LT",
//   },
//   {
//     name: "Luxembourg",
//     dial_code: "+352",
//     code: "LU",
//   },
//   {
//     name: "Macao",
//     dial_code: "+853",
//     code: "MO",
//   },
//   {
//     name: "Macedonia",
//     dial_code: "+389",
//     code: "MK",
//   },
//   {
//     name: "Madagascar",
//     dial_code: "+261",
//     code: "MG",
//   },
//   {
//     name: "Malawi",
//     dial_code: "+265",
//     code: "MW",
//   },
//   {
//     name: "Malaysia",
//     dial_code: "+60",
//     code: "MY",
//   },
//   {
//     name: "Maldives",
//     dial_code: "+960",
//     code: "MV",
//   },
//   {
//     name: "Mali",
//     dial_code: "+223",
//     code: "ML",
//   },
//   {
//     name: "Malta",
//     dial_code: "+356",
//     code: "MT",
//   },
//   {
//     name: "Marshall Islands",
//     dial_code: "+692",
//     code: "MH",
//   },
//   {
//     name: "Martinique",
//     dial_code: "+596",
//     code: "MQ",
//   },
//   {
//     name: "Mauritania",
//     dial_code: "+222",
//     code: "MR",
//   },
//   {
//     name: "Mauritius",
//     dial_code: "+230",
//     code: "MU",
//   },
//   {
//     name: "Mayotte",
//     dial_code: "+262",
//     code: "YT",
//   },
//   {
//     name: "Mexico",
//     dial_code: "+52",
//     code: "MX",
//   },
//   {
//     name: "Micronesia, Federated States of Micronesia",
//     dial_code: "+691",
//     code: "FM",
//   },
//   {
//     name: "Moldova",
//     dial_code: "+373",
//     code: "MD",
//   },
//   {
//     name: "Monaco",
//     dial_code: "+377",
//     code: "MC",
//   },
//   {
//     name: "Mongolia",
//     dial_code: "+976",
//     code: "MN",
//   },
//   {
//     name: "Montenegro",
//     dial_code: "+382",
//     code: "ME",
//   },
//   {
//     name: "Montserrat",
//     dial_code: "+1664",
//     code: "MS",
//   },
//   {
//     name: "Morocco",
//     dial_code: "+212",
//     code: "MA",
//   },
//   {
//     name: "Mozambique",
//     dial_code: "+258",
//     code: "MZ",
//   },
//   {
//     name: "Myanmar",
//     dial_code: "+95",
//     code: "MM",
//   },
//   {
//     name: "Namibia",
//     dial_code: "+264",
//     code: "NA",
//   },
//   {
//     name: "Nauru",
//     dial_code: "+674",
//     code: "NR",
//   },
//   {
//     name: "Nepal",
//     dial_code: "+977",
//     code: "NP",
//   },
//   {
//     name: "Netherlands",
//     dial_code: "+31",
//     code: "NL",
//   },
//   // {
//   // "name": "Netherlands Antilles",
//   // "dial_code": "+599",
//   // "code": "AN"
//   // },
//   {
//     name: "New Caledonia",
//     dial_code: "+687",
//     code: "NC",
//   },
//   {
//     name: "New Zealand",
//     dial_code: "+64",
//     code: "NZ",
//   },
//   {
//     name: "Nicaragua",
//     dial_code: "+505",
//     code: "NI",
//   },
//   {
//     name: "Niger",
//     dial_code: "+227",
//     code: "NE",
//   },
//   {
//     name: "Nigeria",
//     dial_code: "+234",
//     code: "NG",
//   },
//   {
//     name: "Niue",
//     dial_code: "+683",
//     code: "NU",
//   },
//   {
//     name: "Norfolk Island",
//     dial_code: "+672",
//     code: "NF",
//   },
//   {
//     name: "Northern Mariana Islands",
//     dial_code: "+1670",
//     code: "MP",
//   },
//   {
//     name: "Norway",
//     dial_code: "+47",
//     code: "NO",
//   },
//   {
//     name: "Oman",
//     dial_code: "+968",
//     code: "OM",
//   },
//   {
//     name: "Pakistan",
//     dial_code: "+92",
//     code: "PK",
//   },
//   {
//     name: "Palau",
//     dial_code: "+680",
//     code: "PW",
//   },
//   {
//     name: "Palestinian Territory, Occupied",
//     dial_code: "+970",
//     code: "PS",
//   },
//   {
//     name: "Panama",
//     dial_code: "+507",
//     code: "PA",
//   },
//   {
//     name: "Papua New Guinea",
//     dial_code: "+675",
//     code: "PG",
//   },
//   {
//     name: "Paraguay",
//     dial_code: "+595",
//     code: "PY",
//   },
//   {
//     name: "Peru",
//     dial_code: "+51",
//     code: "PE",
//   },
//   {
//     name: "Philippines",
//     dial_code: "+63",
//     code: "PH",
//   },
//   {
//     name: "Pitcairn",
//     dial_code: "+872",
//     code: "PN",
//   },
//   {
//     name: "Poland",
//     dial_code: "+48",
//     code: "PL",
//   },
//   {
//     name: "Portugal",
//     dial_code: "+351",
//     code: "PT",
//   },
//   {
//     name: "Puerto Rico",
//     dial_code: "+1939",
//     code: "PR",
//   },
//   {
//     name: "Qatar",
//     dial_code: "+974",
//     code: "QA",
//   },
//   {
//     name: "Romania",
//     dial_code: "+40",
//     code: "RO",
//   },
//   {
//     name: "Russia",
//     dial_code: "+7",
//     code: "RU",
//   },
//   {
//     name: "Rwanda",
//     dial_code: "+250",
//     code: "RW",
//   },
//   {
//     name: "Reunion",
//     dial_code: "+262",
//     code: "RE",
//   },
//   {
//     name: "Saint Barthelemy",
//     dial_code: "+590",
//     code: "BL",
//   },
//   {
//     name: "Saint Helena, Ascension and Tristan Da Cunha",
//     dial_code: "+290",
//     code: "SH",
//   },
//   {
//     name: "Saint Kitts and Nevis",
//     dial_code: "+1869",
//     code: "KN",
//   },
//   {
//     name: "Saint Lucia",
//     dial_code: "+1758",
//     code: "LC",
//   },
//   {
//     name: "Saint Martin",
//     dial_code: "+590",
//     code: "MF",
//   },
//   {
//     name: "Saint Pierre and Miquelon",
//     dial_code: "+508",
//     code: "PM",
//   },
//   {
//     name: "Saint Vincent and the Grenadines",
//     dial_code: "+1784",
//     code: "VC",
//   },
//   {
//     name: "Samoa",
//     dial_code: "+685",
//     code: "WS",
//   },
//   {
//     name: "San Marino",
//     dial_code: "+378",
//     code: "SM",
//   },
//   {
//     name: "Sao Tome and Principe",
//     dial_code: "+239",
//     code: "ST",
//   },
//   {
//     name: "Saudi Arabia",
//     dial_code: "+966",
//     code: "SA",
//   },
//   {
//     name: "Senegal",
//     dial_code: "+221",
//     code: "SN",
//   },
//   {
//     name: "Serbia",
//     dial_code: "+381",
//     code: "RS",
//   },
//   {
//     name: "Seychelles",
//     dial_code: "+248",
//     code: "SC",
//   },
//   {
//     name: "Sierra Leone",
//     dial_code: "+232",
//     code: "SL",
//   },
//   {
//     name: "Singapore",
//     dial_code: "+65",
//     code: "SG",
//   },
//   {
//     name: "Slovakia",
//     dial_code: "+421",
//     code: "SK",
//   },
//   {
//     name: "Slovenia",
//     dial_code: "+386",
//     code: "SI",
//   },
//   {
//     name: "Solomon Islands",
//     dial_code: "+677",
//     code: "SB",
//   },
//   {
//     name: "Somalia",
//     dial_code: "+252",
//     code: "SO",
//   },
//   {
//     name: "South Africa",
//     dial_code: "+27",
//     code: "ZA",
//   },
//   {
//     name: "South Sudan",
//     dial_code: "+211",
//     code: "SS",
//   },
//   {
//     name: "South Georgia and the South Sandwich Islands",
//     dial_code: "+500",
//     code: "GS",
//   },
//   {
//     name: "Spain",
//     dial_code: "+34",
//     code: "ES",
//   },
//   {
//     name: "Sri Lanka",
//     dial_code: "+94",
//     code: "LK",
//   },
//   {
//     name: "Sudan",
//     dial_code: "+249",
//     code: "SD",
//   },
//   {
//     name: "Suriname",
//     dial_code: "+597",
//     code: "SR",
//   },
//   {
//     name: "Svalbard and Jan Mayen",
//     dial_code: "+47",
//     code: "SJ",
//   },
//   {
//     name: "Swaziland",
//     dial_code: "+268",
//     code: "SZ",
//   },
//   {
//     name: "Sweden",
//     dial_code: "+46",
//     code: "SE",
//   },
//   {
//     name: "Switzerland",
//     dial_code: "+41",
//     code: "CH",
//   },
//   {
//     name: "Syrian Arab Republic",
//     dial_code: "+963",
//     code: "SY",
//   },
//   {
//     name: "Taiwan",
//     dial_code: "+886",
//     code: "TW",
//   },
//   {
//     name: "Tajikistan",
//     dial_code: "+992",
//     code: "TJ",
//   },
//   {
//     name: "Tanzania, United Republic of Tanzania",
//     dial_code: "+255",
//     code: "TZ",
//   },
//   {
//     name: "Thailand",
//     dial_code: "+66",
//     code: "TH",
//   },
//   {
//     name: "Timor-Leste",
//     dial_code: "+670",
//     code: "TL",
//   },
//   {
//     name: "Togo",
//     dial_code: "+228",
//     code: "TG",
//   },
//   {
//     name: "Tokelau",
//     dial_code: "+690",
//     code: "TK",
//   },
//   {
//     name: "Tonga",
//     dial_code: "+676",
//     code: "TO",
//   },
//   {
//     name: "Trinidad and Tobago",
//     dial_code: "+1868",
//     code: "TT",
//   },
//   {
//     name: "Tunisia",
//     dial_code: "+216",
//     code: "TN",
//   },
//   {
//     name: "Turkey",
//     dial_code: "+90",
//     code: "TR",
//   },
//   {
//     name: "Turkmenistan",
//     dial_code: "+993",
//     code: "TM",
//   },
//   {
//     name: "Turks and Caicos Islands",
//     dial_code: "+1649",
//     code: "TC",
//   },
//   {
//     name: "Tuvalu",
//     dial_code: "+688",
//     code: "TV",
//   },
//   {
//     name: "Uganda",
//     dial_code: "+256",
//     code: "UG",
//   },
//   {
//     name: "Ukraine",
//     dial_code: "+380",
//     code: "UA",
//   },
//   {
//     name: "United Arab Emirates",
//     dial_code: "+971",
//     code: "AE",
//   },
//   {
//     name: "United Kingdom",
//     dial_code: "+44",
//     code: "GB",
//   },
//   {
//     name: "United States",
//     dial_code: "+1",
//     code: "US",
//   },
//   {
//     name: "Uruguay",
//     dial_code: "+598",
//     code: "UY",
//   },
//   {
//     name: "Uzbekistan",
//     dial_code: "+998",
//     code: "UZ",
//   },
//   {
//     name: "Vanuatu",
//     dial_code: "+678",
//     code: "VU",
//   },
//   {
//     name: "Venezuela, Bolivarian Republic of Venezuela",
//     dial_code: "+58",
//     code: "VE",
//   },
//   {
//     name: "Vietnam",
//     dial_code: "+84",
//     code: "VN",
//   },
//   {
//     name: "Virgin Islands, British",
//     dial_code: "+1284",
//     code: "VG",
//   },
//   {
//     name: "Virgin Islands, U.S.",
//     dial_code: "+1340",
//     code: "VI",
//   },
//   {
//     name: "Wallis and Futuna",
//     dial_code: "+681",
//     code: "WF",
//   },
//   {
//     name: "Yemen",
//     dial_code: "+967",
//     code: "YE",
//   },
//   {
//     name: "Zambia",
//     dial_code: "+260",
//     code: "ZM",
//   },
//   {
//     name: "Zimbabwe",
//     dial_code: "+263",
//     code: "ZW",
//   },
// ];
export const CountryCodes = [
  { code: "AD", label: "Andorra", phone: "376" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  { code: "AF", label: "Afghanistan", phone: "93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
  },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
  },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
  },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  {
    code: "FR",
    label: "France",
    phone: "33",
  },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91",suggested:true },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "IQ", label: "Iraq", phone: "964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
  },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
  },
  { code: "ME", label: "Montenegro", phone: "382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
  },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  { code: "SV", label: "El Salvador", phone: "503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  { code: "SZ", label: "Swaziland", phone: "268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TD", label: "Chad", phone: "235" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  { code: "TV", label: "Tuvalu", phone: "688" },
  {
    code: "TW",
    label: "Taiwan",
    phone: "886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  {
    code: "US",
    label: "United States",
    phone: "1",
  },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "VE", label: "Venezuela", phone: "58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
  },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];
