//
import Recharge from "../components/Recharge";
import PaymentCallback from "../components/PaymentCallback";
import Transactions from "../components/Transactions";
// import Register from './Register';
// import DashboardApp from "./DashboardApp";
// import UserList from "./Users/UserList";
// import UserRole from "./Users/UserRole";
// import UserPermissions from "./Users/UserPermissions";

const config = [
  Recharge,
  PaymentCallback,
  Transactions,
  // Login,
  // DashboardApp,
  // UserList,
  // UserRole,
  // UserPermissions,
];

export default config;
